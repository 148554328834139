import { Injectable } from '@angular/core';
import { ToastController, AlertController,ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertesService {
  public iduser: any;
  public tipouser: any;
  public mailuser: any='';
  public token: any;
  public nomuser: any;
  public numuser: any;
  public pass: any;
  public codiuser: any;
  public actualizar: any=0;
  public userin: any = 0;
  public login=false;

  public path2 = 'https://premiumimage.es/arkria/api/api.php';
  public pathleer2 = 'https://premiumimage.es/arkria/api/leer.php';
  public pathmail = 'https://premiumimage.es/arkria/api/verifmail2.php';
  public pathexcel = 'https://premiumimage.es/arkria/api/excelphp.php';
  public pathfexcel = 'https://premiumimage.es/arkria/assets/excel/';
  public pathfoto = 'https://premiumimage.es/arkria/assets/imagen/';
  public pathpdf = 'https://premiumimage.es/arkria/assets/pdf/';
  public pathapi = 'https://premiumimage.es/api/';
  // public path2 = 'https://arkria.com/arkria_app/api/api.php';
  // public pathleer2 = 'https://arkria.com/arkria_app/api/leer.php';

  // public path2 = 'https://rapports.arkria.com/api/api.php';
  // public pathleer2 = 'https://rapports.arkria.com/api/leer.php';
  // public pathmail = 'https://arkria.com/arkria_app/api/verifmail2.php';
  // public pathexcel = 'https://rapports.arkria.com/api/excelphp.php';
  // public pathfexcel = 'https://rapports.arkria.com/assets/excel/';
  // public pathfoto = 'https://rapports.arkria.com/assets/imagen/';
  // public pathpdf = 'https://rapports.arkria.com/assets/pdf/';
  // public pathapi = 'https://rapports.arkria.com/api/';

  constructor(public toastCtrl: ToastController, public alertCtrl: AlertController, public modalCtrl:ModalController) { }

  async presentToast(msg, color1) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: color1,
      cssClass: 'fsize16',
    });
    toast.present();
  }
  async presentAlert(msg2) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: msg2,
      buttons: ['OK']
    });

    await alert.present();
  }
}
